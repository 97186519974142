<template>
  <div>
    <!-- 开通流程 -->
    <div class="process">
      <span>♦ ♦ ♦</span>
      <span class="spanc">开通流程</span>
      <span>♦ ♦ ♦</span>
    </div>
    <!-- 支付流程 -->
    <div class="paypro">
      <div style="overflow: hidden;display: flex;margin:0 auto;width: 70%;">
        <div class="mybkkdL">
          01 立即开通
        </div>
        <div class="mybkkd">
          02 选择支付
        </div>
        <div class="mybkkdR">
          03 享受权益
        </div>
      </div>
    </div>
    <div class="process">
      <span>♦ ♦ ♦</span>
      <span class="spanc">尊享权益</span>
      <span>♦ ♦ ♦</span>
    </div>

    <div class="listUi">
      <!-- <ul v-for="item in 20">
                <li>院校计划</li>
                <li>√</li>
                <li>×</li>
            </ul> -->
      <div class="btns">
        <div>普通用户</div>
        <div>自主卡VIP</div>
        <div>智能卡VIP</div>
      </div>
      <ul v-for="(item, index) in listData" :key="index">
        <li>{{ item.value }}</li>
         <li>{{ item.common ? '√' : 'X' }}</li>
        <li>{{ item.zzVip  ? '√' : 'X'   }}</li>
        <li>{{ item.znVip  ? '√' : 'X'  }}</li>
      </ul>
    </div>
    <div class="listUi" style="border-bottom: none;">
        <p class="toastboxItem" v-for="(item,index) in toastList" :key="index">{{ item}}</p>
    </div>
    <!-- <div>
      <div class="record">激活记录</div>
      <div class="tabel" v-if="jhData.length > 0">
        <el-table :data="jhData" border style="width: 100%">
          <el-table-column prop="cardNumber" label="卡号">
          </el-table-column>
          <el-table-column prop="cardType" label="类型">
          </el-table-column>
          <el-table-column prop="createTime" label="激活时间">
          </el-table-column>
        </el-table>
      </div>
      <div class="con" v-else>
        — 暂无记录 —
      </div>
    </div> -->
  </div>
</template>

<script>
import { data } from './mock.js'
import { getrecord } from '@/api/user'
name: 'becomeVip1';
export default {
  data() {
    return {
      listData: [],
      jhData: [],
      toastList:[
        '1，普通用户出分前可以无限次改分和选科，可以体验1次智能填报，出分后不能免费体验。',
        '2，自主vip用户出分前可以无限次改分数（不允许改科），出分后只能改一次分数（不允许改科）；出分前可以体验1次智能填报，出分后（志愿填报期间）不能免费体验。',
        '3，智能vip用户出分前可以无限次改分（不允许改科），出分后可以改一次分数（不允许改科）。',

      ]
    };
  },
  created() {
    this.listData = data;
    this.gethost()
  },
  methods: {
    gethost() {

      let phone = localStorage.getItem('phonenumber')
      if (phone) {
        getrecord({ userPhone: phone }).then(res => {
          this.jhData = res.data

        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.process {
  width: 50%;
  margin: 3% 0 3% 41%;

  .spanc {
    color: #73664D;
    margin: 0px 5%;
  }

  span {
    color: #C8BEAC;
    margin-left: 1%;
    font-size: 20px;
  }
}

.paypro {
  .mybkkdL {
    /* flex: 1; */
    width: 40%;
    height: 40px;
    position: relative;
    background: #30C9B2;
    border-radius: 15px 0 0 15px;
    color: white;
    text-align: center;
    line-height: 40px;
  }

  .mybkkdL:before {
    content: "";
    position: absolute;
    right: -9px;
    z-index: 9;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #30C9B2;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left-width: 10px;
  }

  .mybkkd {
    width: 40%;
    height: 40px;
    position: relative;
    background: #30C9B2;
    margin-left: 5px;
    color: white;
    text-align: center;
    line-height: 40px;
  }

  .mybkkd:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid white;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left-width: 11px;
  }

  .mybkkd:before {
    content: "";
    position: absolute;
    right: -9px;
    z-index: 9;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #30C9B2;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left-width: 10px;
  }

  .mybkkdR {
    width: 40%;
    height: 40px;
    position: relative;
    background: #F3E3B5;
    margin-left: 5px;
    color: white;
    border-radius: 0 15px 15px 0;
    text-align: center;
    line-height: 40px;
  }

  .mybkkdR:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid white;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left-width: 11px;
  }

  .mybkkdR:before {
    content: "";
    position: absolute;
    right: -10px;
    z-index: 9;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 20px solid #30C9B2;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left-width: 10px;
  }
}

.listUi {
  width: 80%;
  margin: auto;
  // margin-top: 7%;
  border-bottom: 1px solid #30C9B2;
  position: relative;
  color: #30C9B2;

  ul {
    display: flex;
    justify-content: space-between;
    border-left: 2px solid #30C9B2;

    li {
      width: 23%;
      border-right: 2px solid #30C9B2;
      text-align: center;
      padding: 10px;
    }

  }



}
   .listUi ul:nth-child(2){
       border-top: 2px solid #30C9B2;
    }

.btns {
  display: flex;
    width: 70%;
    justify-content: space-around;
    margin: 2% 0 2% auto;



  div {
    width: 21%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    // border: 1px solid #30C9B2;
    border-radius: 30px;
    color: #f7f7f7;
    // background-color: #30C9B2;
    // 渐变背景色
    background: linear-gradient(90deg, #9dfdee 0%, #30C9B2 100%);
    cursor: pointer;
  }

  :nth-child(1) {
    background: linear-gradient(90deg, #DCDCDC 0%, #DCDCDC 100%);
    border: 0;

  }
}
.toastboxItem{

    line-height: 30px;
}
.record {
  width: 200px;
  height: 30px;
  border-left: 5px solid #30C9B2;
  padding-left: 10px;
  line-height: 30px;
  font-size: 20px;
  margin: 60px 0 0 10%;
}

.con {
  width: 100%;
  height: 200px;
  text-align: center;
  line-height: 200px;
  font-size: 15px;
  color: #666666;

}

.tabel {
  width: 80%;
  margin: 30px 0 0 10%;
}
</style>
