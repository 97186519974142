export const data = [
  {value: '自主填报',common:false,zzVip: true,znVip: true},
  {
    value: '智能填报',
    common: false,
    zzVip: false,
    znVip: true
  },
  {
    value: '专家推荐',
    common: false,
    zzVip: false,
    znVip: true
  },
  {
    value: '硕博点',
    common: false,
    zzVip: false,
    znVip: true
  },
  {
    value: '名企招聘',
    common: false,
    zzVip: false,
    znVip: true
  },

    {
      value: '查学校',
      common: true,
      zzVip: true,
      znVip: true
    }, {
      value: '查专业',
      common: true,
      zzVip: true,
      znVip: true
    },
     {
       value: '查位次',
       common: false,
       zzVip: true,
       znVip: true
     }, {
       value: '查职业',
       common: true,
       zzVip: true,
       znVip: true
     },
      {
        value: '提前批',
        common: false,
        zzVip: true,
        znVip: true
      }, {
        value: '招生计划',
        common: false,
        zzVip: true,
        znVip: true
      },
       {
         value: '学科评估',
         common: false,
         zzVip: true,
         znVip: true
       }, {
         value: '批次线',
         common: true,
         zzVip: true,
         znVip: true
       },
        {
          value: '测一测',
          common: false,
          zzVip: true,
          znVip: true
        },
 ]
